$mobile-screen-size : 600px; 

$screenBackgroundColor1 : #749494;
$screenBackgroundColor2 : #333536;
$sideBackgroundColor : black;

$block1Color : rgba(255, 0, 0, 0.644);
$block2Color : rgba(255, 123, 0, 0.644);
$block3Color : rgba(255, 255, 0, 0.644);
$block4Color : rgba(0, 255, 0, 0.644);
$block5Color : rgba(0, 0, 255, 0.644);
$block6Color : rgba(151, 0, 151, 0.644);
$block7Color : rgba(0, 252, 252, 0.644);
