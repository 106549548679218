
.mainGameContainer {
    position: absolute;
    top: 50%;
    right: 50%;
    -ms-transform: translate(50%,-50%);
    transform: translate(50%,-50%);
    width: 100%;

}

.mainGameGrid {
    background-color: black;
    background-image: linear-gradient(#749494, #333536);
    margin: auto;
    -webkit-box-shadow: 0px 0px 100px 22px rgba(255,255,255,0.17); 
    box-shadow: 0px 0px 100px 22px rgba(255,255,255,0.17);
}


