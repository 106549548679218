
.GameStatsDisplayHidden {
    display: none !important;
}

.GameStatsDisplayFull
{
    
    z-index: 1;
    user-select: none;
    position: fixed;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
    margin: 10px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #2e393a;
    border-radius: 5px;

    color: rgba(255, 255, 255, 0.75);
    outline: none !important;



    .StatsDisplayRow {
        z-index: 1;
        user-select: none;
        position: fixed;
        top: 50%;
        transform: translate(0%, -50%);
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 65px;
        border-radius: 5px;
        outline: none !important;

    }
    
    .GameScoreDisplaySection {
        -webkit-box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17); 
        box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17);
        border-radius: 5px;
    }

    .NextBlockAndLevelDisplaySection {
        -webkit-box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17); 
        box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17);
        border-radius: 5px;

        .scoreItem {
            justify-content: center;
        }

    }

    .scoreItem {
        display: flex;
        flex-direction: row;
        margin: 10px;

        .scoreIcon {
            font-size: 275%;
        }

        .scoreNumber {
            margin-left: 10px;
            font-size: 250%;
        }
    }


    .NextBlockDisplay {

        h1, h2 {
            pointer-events: none;
            text-align: center;
            margin: -68px 10px 0px 10px;
            z-index: 500;
        }


        .BlockStatDisplayContainer {
            margin: 10px;
        }
    
        .BlockStatGridDisplay  {
            margin: auto;
            width: 120px;
            z-index: 100;
        }
        
        .BlockStatGridDisplaygridRow{
            z-index: 100;
            padding: 0;
            margin: 0;
            list-style: none;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: row;
            justify-content: space-around;
            line-height:30px;
        }

    }

    .Popup h1, h2 {
    
        font-size: 275%;
        pointer-events: none;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    
    .Popup div button {
        color:inherit;
        text-align: center;
        font-family:inherit;
        font-size: inherit;
        background: rgba(255, 255, 255, 0.50);
        font-size: 175%;
        font-weight: bold;
        padding: 0.3rem 3.4rem;
        border: 3px solid black;
        height: 2.75em;
        border-radius: 5px;
        margin-right: 1.6rem;
        transition: all 0.2s;
        width: 100%;
        margin-bottom: 10px;
        outline: none !important;
    }
    
    .Popup div button i {
        margin-left: 15px;
        text-align: right;
    }
    
    .Popup div button:hover {
    
        box-shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.1,1.1);
    }
    
    .Popup div button:active {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
        transform: translate(0, 0);
    }
    
    .PopupPaused
    {
        font-family: monospace;
        text-shadow: 1px white;
    }

} 


.GameStatsDisplayCompact
{
    z-index: 1;
    user-select: none;
    position: fixed;
    right: 50%;
    transform: translate(50%, 0%);
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.75);
    outline: none !important;


    .StatsDisplayRow {

        z-index: 10;
        user-select: none;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.75);
        outline: none !important;
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .GameScoreDisplaySection {
            display: flex;
            -webkit-box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17); 
            box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17);
        }

        .scoreItem{
            margin: 0px 10px 0px 10px;
            .scoreIcon {
                font-size: 120%;
                pointer-events: none;
                text-align: center;
                margin: 0px 0px 0px 0px;
                z-index: 500; 
            }
            .scoreNumber {
                font-size: 100%;
                pointer-events: none;
                text-align: center;
                margin: 0px 0px 0px 0px;
                z-index: 500;
            }
        }

        .NextBlockAndLevelDisplaySection
        {
            display: flex;
            -webkit-box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17); 
            box-shadow: 0px 0px 25px 5px  rgba(255,255,255,0.17);

        }
        .NextBlockDisplay {

            h1, h2 {
                font-size: 100%;
                pointer-events: none;
                text-align: center;
                margin: -30px 10px 0px 10px;
                z-index: 500;
            }


            .BlockStatDisplayContainer {
                margin: 10px;
            }
        
            .BlockStatGridDisplay  {
                margin: auto;
                width: 55px;
                z-index: 100;
            }
            
            .BlockStatGridDisplaygridRow{
                z-index: 100;
                padding: 0;
                margin: 0;
                list-style: none;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-flow: row;
                justify-content: space-around;
                line-height:30px;
            }

        }

        .GameLevelDisplay {

        }

    }


    


    .Popup h1, h2 {
        

    }

    .GameStatsDisplay h1, h2 {
        
        font-size: 100%;
        pointer-events: none;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .Popup div button {
        color:inherit;
        text-align: center;
        font-family:inherit;
        font-size: inherit;
        background: rgba(255, 255, 255, 0.50);
        font-size: 175%;
        font-weight: bold;
        padding: 0.3rem 3.4rem;
        border: 3px solid black;
        height: 2.75em;
        border-radius: 5px;
        margin-right: 1.6rem;
        transition: all 0.2s;
        width: 100%;
        margin-bottom: 10px;
        outline: none !important;
    }

    .Popup div button i {
        margin-left: 15px;
        text-align: right;
    }

    .Popup div button:hover {
        box-shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.1,1.1);
    }
    
    .Popup div button:active {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
        transform: translate(0, 0);
    }

    .PopupPaused
    {
        font-family: monospace;
        text-shadow: 1px white;
    }

} 
