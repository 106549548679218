@import '../../vars.scss';

.gridBlock0 {
    background-color: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.gridBlock1 {
    background-color: $block1Color;
}

.gridBlock2 {
    background-color: $block2Color;
}

.gridBlock3 {
    background-color: $block3Color;;
}

.gridBlock4 {
    background-color: $block4Color;
}

.gridBlock5 {
    background-color: $block5Color;
}

.gridBlock6 {
    background-color: $block6Color;
}

.gridBlock7 {
    background-color: $block7Color;
}

.gridBlock {
    border: rgba(0, 0, 0, .25) .1em solid;
    color: white;
    text-align: center;
    flex: 1 0 auto;
    height:auto;
    -webkit-box-shadow: 5px 8px 18px -1px rgba(0,0,0,0.73); 
    box-shadow: 5px 8px 18px -1px rgba(0,0,0,0.73);
}
.gridBlock:before {
    content:'';
    float:left;
    padding-top:100%;
}

.gridBlockDisplay {
    border: none !important;

}