
.PopupBackgroundContent {
    filter: blur(9px);
    -webkit-filter: blur(8px);
}

.PopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.20);
} 

.Popup
{

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 1.6rem 3rem;
    border: 3px solid black;
    border-radius: 5px;
    background: rgba(155, 236, 250, 0.205);
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.75);
    outline: none !important;

} 

.Popup h1, h2 {
    
    font-size: 275%;
    pointer-events: none;
    text-align: center;
}

.Popup div button {
    color:inherit;
    text-align: center;
    font-family:inherit;
    font-size: inherit;
    background: rgba(255, 255, 255, 0.50);
    font-size: 175%;
    font-weight: bold;
    padding: 0.3rem 3.4rem;
    border: 3px solid black;
    height: 2.75em;
    border-radius: 5px;
    margin-right: 1.6rem;
    transition: all 0.2s;
    width: 100%;
    margin-bottom: 10px;
    outline: none !important;
}

.Popup div button i {
    margin-left: 15px;
    text-align: right;
}

.Popup div button:hover {

    box-shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.1,1.1);
  }
  
.Popup div button:active {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    transform: translate(0, 0);
  }

.PopupPaused
{
    font-family: monospace;
    text-shadow: 1px white;
}